interface Metadata {
  alt: string;
}

export const metadata: Record<string, Metadata> = {
  '3-reasons-banner.jpeg': {
    alt: 'hands reaching across table to shake on a deal'
  },
  'allie-and-kris.jpg': {
    alt: 'Happy Own Up customers Allie and Kris smile at the camera'
  },
  'andrew.jpg': {
    alt: 'smiling person standing on a Boston street corner with red brick buildings in the background'
  },
  'andrew-review.jpg': {
    alt: 'Happy Own Up customer Andrew smiles at the camera'
  },
  'banker.png': {
    alt: 'smiling man with dress shirt and suit jacket'
  },
  'black-dress-same.png': {
    alt: 'dark dresses on black hangers on a white closet rod'
  },
  'brent.jpg': {
    alt: 'Own Up Co-founder Brent Shields smiles widely, looking off to the right of the camera'
  },
  'brent-headshot.jpg': {
    alt: 'Own Up Co-founder Brent Shields smiles at the camera'
  },
  'brian.jpg': {
    alt: 'Real estate agent Brian Head smiles at the camera'
  },
  'bullseye.svg': {
    alt: "target with an arrow in the bullseye conveying our ability to meet the buyer's goals"
  },
  'chat.svg': {
    alt: 'icon of a chat bubble'
  },
  'claudel.jpg': {
    alt: 'Real estate agent Claudel Frederique smiles at the camera'
  },
  'cody-and-erica.jpg': {
    alt: 'smiling woman with sunglasses and smiling man with sunglasses'
  },
  'cody-and-erica-review.jpg': {
    alt: 'Happy Own Up customers Cody and Erica smile at the camera'
  },
  'column-building.svg': {
    alt: 'Bank, representing a lending institution'
  },
  'conversation-bubbles.svg': {
    alt: 'Conversation bubbles, conveying the idea of negotiation'
  },
  'courtney.jpg': {
    alt: 'Real estate agent Courtney Lott smiles at the camera'
  },
  'document-with-checkmark.svg': {
    alt: 'icon of document with a check mark on it'
  },
  'document-with-checkmark-green.svg': {
    alt: 'icon of document with a check mark on it'
  },
  'document.svg': {
    alt: 'icon of document'
  },
  'documents.svg': {
    alt: 'Documents with picture of a person, conveying the idea of customization'
  },
  'documents-with-arrows.svg': {
    alt: 'Documents with arrows pointing to each other, conveying the idea of comparison'
  },
  'dollar-bills.svg': {
    alt: 'icon of two dollar bills'
  },
  'emily-and-evan.png': {
    alt: 'Happy Own Up customers Emily and Evan smile at the camera'
  },
  'evan.jpg': {
    alt: 'Happy Own Up customer Evan and a friend smile at the camera'
  },
  'green-line.svg': {
    alt: ''
  },
  'green-arrow.svg': {
    alt: ''
  },
  'handshake.svg': {
    alt: 'image of two hands shaking, conveying the idea of working together'
  },
  'happy-couple-smiling.jpg': {
    alt: 'Two happy Own Up customers smile at the camera'
  },
  'heart.svg': {
    alt: 'circle with a outline of a heart inside conveying our love for the clients'
  },
  'house-outside-trees.svg': {
    alt: 'outline of house with trees and clouds'
  },
  'house-with-money-inside.svg': {
    alt: 'icon of a house with dollar sign inside'
  },
  'house-with-percentage-inside.svg': {
    alt: 'icon of a house with percentage sign inside'
  },
  'icon-binoculars.png': {
    alt: 'A simple black-lined icon of binoculars on a solid green background'
  },
  'james.jpg': {
    alt: 'Happy Own Up customer James smiles at the camera'
  },
  'justice.png': {
    alt: 'statue of Lady Justice with blindfold'
  },
  'k-at-laptop.jpg': {
    alt: 'Own Up Employee K, working at a laptop'
  },
  'katina.jpg': {
    alt: 'Own Up Sales Operations Manager Katina Nardi smiles at the camera'
  },
  'letter.svg': {
    alt: 'Letter in envelope'
  },
  'liz-and-matt.png': {
    alt: 'Own Up employees Liz and Matt huddle around a laptop'
  },
  'locked-folder.svg': {
    alt: 'File folder with lock on it, conveying the idea of confidentiality'
  },
  'max.jpg': {
    alt: 'Happy Own Up customer Max and friends smile at the camera'
  },
  'michael-and-marina.jpg': {
    alt: 'Happy Own Up customers Michael and Marina smile at the camera'
  },
  'mike-and-patrick.jpg': {
    alt: 'two men sitting at table with laptop'
  },
  'mike-and-patrick-at-laptop.jpg': {
    alt: 'Own Up Co-founders Patrick and Mike chat at a hightop table in a bright room filled with sunlight'
  },
  'mike-and-patrick-in-park.png': {
    alt: 'Own Up Co-founders Patrick and Mike stand side by side in a park and smile at the camera'
  },
  'mike-bobblehead.jpg': {
    alt: 'A bobblehead figurine of a standing business person wearing a black suit and red tie'
  },
  'mike-headshot.jpg': {
    alt: 'Own Up Co-founder Mike Tassone smiles at the camera'
  },
  'mike-pointing-at-monitor.jpg': {
    alt: 'Own Up Co-founder Mike Tassone seated at a desk, pointing to a computer monitor while a colleague observes'
  },
  'mobile-device.svg': {
    alt: 'Mobile device, conveying the idea of a phone call'
  },
  'new-homeowners.jpg': {
    alt: 'New homeowners looking back at their house'
  },
  'open-door.svg': {
    alt: "ajar door conveying we're available and the door is always open"
  },
  'overlapping-screens.svg': {
    alt: 'icon of two overlapping screens conveying the idea of transparent sharing of information'
  },
  'own-up-on-devices.png': {
    alt: 'Own-Up-pages-on-devices'
  },
  'parent-and-baby-at-computer.jpg': {
    alt: 'A parent with smiling baby on lap browses the internet'
  },
  'patrick-headshot.jpg': {
    alt: 'Own Up Co-founder Patrick Boyaggi smiles at the camera'
  },
  'percent.svg': {
    alt: 'percent sign'
  },
  'piggy-bank.svg': {
    alt: 'image of a piggy bank, conveying the idea of financial savings'
  },
  'press-bloomberg.png': {
    alt: 'Bloomberg News'
  },
  'press-forbes.png': {
    alt: 'Forbes'
  },
  'press-npr.png': {
    alt: 'National Public Radio'
  },
  'press-us-news.png': {
    alt: 'U.S. News'
  },
  'press-usa-today.png': {
    alt: 'USA Today'
  },
  'press-yahoo-finance.png': {
    alt: 'Yahoo Finance'
  },
  'prospective-borrower.svg': {
    alt: 'image of a person, representing a prospective Own Up borrower'
  },
  'quarter.png': {
    alt: 'U.S. quarter dollar coin'
  },
  'red-squiggle.svg': {
    alt: ''
  },
  'safe.svg': {
    alt: 'Safety deposit box conveying the idea of security'
  },
  'scale.svg': {
    alt: 'image of a double pan balance scale, conveying the idea of comparison'
  },
  'shaking-hands.jpg': {
    alt: 'Two people shaking hands at the end of a meeting'
  },
  'smiling-buyers-blue-shirts.jpg': {
    alt: 'Two people in a well-lit room with plaid and stripped blue shirts smiling at the camera'
  },
  'smiling-new-homeowners.jpg': {
    alt: 'A laughing couple sits close together on the stoop of their new house. The person on the right holds the key to their house.'
  },
  'time-is-money.svg': {
    alt: 'image of a clock overlapping a dollar sign, conveying "time is money"'
  },
  'todd.jpg': {
    alt: 'Happy Own Up customer Todd smiles at the camera'
  },
  'two-adults-two-children.png': {
    alt: 'two seated people, each holding a young child'
  },
  'two-people-in-garden.png': {
    alt: 'smiling couple in front of flowering plants'
  },
  'two-people-with-house.png': {
    alt: 'smiling couple standing in front of their new home'
  },
  'tyler.jpg': {
    alt: 'Associate Sales Manager Tyler smiles at the camera'
  },
  'website.svg': {
    alt: 'Website with graph and information, conveying the idea of insight'
  },
  'x.svg': {
    alt: 'x icon'
  }
};
