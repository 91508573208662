export type StartNowReviewProps = {
  image: string;
  quote: string;
  attribution: string;
};

export const startNowReviews: StartNowReviewProps[] = [
  {
    image: 'two-adults-two-children.png',
    quote: "I've probably referred seven or eight people at this point.",
    attribution: 'Karim S.'
  },
  {
    image: 'two-people-with-house.png',
    quote: 'What really stood out to me was the transparency that we got.',
    attribution: 'Katherine D.'
  },
  {
    image: 'two-people-in-garden.png',
    quote: 'The ability to just do a soft credit check was much more comfortable.',
    attribution: 'Erika H.'
  }
];
