import { FullWidthField, ImageQuery, SwooshDivider } from '@rategravity/marketing-components';
import { createOwnUpComponent, createOwnUpStyle } from '@rategravity/own-up-component-library';
import React from 'react';
import { colors } from '../../modules/colors';
import { ResponsiveSquiggleDivider } from '../squiggle-divider';
import { Ankle } from './ankle';
import { Header } from './header';
import { Hero } from './hero';
import { Reviews } from './reviews';
import { StartNowValueProps as ValueProps } from './value-props';

const SwooshDividerWrapperStyle = createOwnUpStyle({
  '>div': {
    transform: 'rotateY(180deg)'
  },
  marginBottom: '-1px'
});

const SwooshDividerWrapper = createOwnUpComponent('div', SwooshDividerWrapperStyle);

export const StartNowContent = ({ data }: { data: ImageQuery }) => {
  return (
    <React.Fragment>
      <FullWidthField backgroundColor={colors.AQUA} desktopMaxWidth={1280} mobileLateralGutter={0}>
        <Header />
        <Hero />
      </FullWidthField>
      <ValueProps />
      <ResponsiveSquiggleDivider maxWidth={'90%'} />
      <Reviews data={data} />
      <SwooshDividerWrapper>
        <SwooshDivider
          topColor={colors.WHITE}
          bottomColor={colors.AQUA}
          desktopMaxWidth={1280}
          mobileLateralGutter={0}
        />
      </SwooshDividerWrapper>
      <FullWidthField backgroundColor={colors.AQUA} desktopMaxWidth={1280} mobileLateralGutter={0}>
        <Ankle />
      </FullWidthField>
    </React.Fragment>
  );
};
