import { ImageQuery } from '@rategravity/marketing-components';
import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from '../components/layout';
import { StartNowContent } from '../components/start-now';
import { colors } from '../modules/colors';

export const query = graphql`
  query {
    images: allFile(filter: { relativePath: { regex: "/start-now/" }, extension: { ne: "svg" } }) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

export const StartNow = ({ data }: { data: ImageQuery }) => (
  <Layout>
    <Helmet>
      <style>{`
    body {
      background-color: ${colors.WHITE}
    }
    nav {
      background-color: ${colors.AQUA}
    }`}</style>
    </Helmet>
    <StartNowContent data={data} />
  </Layout>
);
export default StartNow;
