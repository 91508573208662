import { getImage, ImageQuery } from '@rategravity/marketing-components';
import React from 'react';
import { HeadlineTextWithStars } from '../../../components/headline-text-with-stars';
import { GroupedReviews } from '../../../components/reviews/grouped-reviews';
import { startNowReviews } from '../../../data/content/start-now/reviews';
import { metadata } from '../../../data/metadata/images';

export const startNowReviewImageStyle = {
  borderRadius: '50%',
  maxWidth: '250px',
  margin: '30px'
};

export const ReviewsHeadline = (
  <div style={{ display: 'flex', justifyContent: 'center', width: '80%', margin: '0 auto' }}>
    <HeadlineTextWithStars
      headline={'What people are saying'}
      subtext={'Thousands of reviews averaging 4.9/5 stars across Google, Facebook and Zillow'}
    />
  </div>
);

export const Reviews = ({ data }: { data: ImageQuery }) => {
  const reviewsData = startNowReviews.map(({ image, quote, attribution }) => ({
    Image: getImage(data, image, metadata, startNowReviewImageStyle) || <></>,
    quote,
    attribution
  }));

  return <GroupedReviews headline={ReviewsHeadline} reviewsData={reviewsData} />;
};
