import Handshake from '../../../images/icons/handshake.svg';
import PiggyBank from '../../../images/icons/piggy-bank.svg';
import TimeIsMoney from '../../../images/icons/time-is-money.svg';

export const startNowValueProps = [
  {
    data: {
      catchphrase: 'Know more, save more',
      text: "Our technology is built to deliver real data for your unique scenario. Stop wondering if you're getting a good deal and know for sure."
    },
    Image: {
      svg: PiggyBank,
      imageName: 'piggy-bank.svg',
      height: 60
    }
  },
  {
    data: {
      catchphrase: 'Hassle-free mortgage shopping',
      text: 'Say goodbye to hard credit checks, spam calls and sales pressure. Buying a home is hard enough. We make finding a great mortgage easy.'
    },
    Image: {
      svg: TimeIsMoney,
      imageName: 'time-is-money.svg',
      height: 60
    }
  },
  {
    data: {
      catchphrase: 'Expert, unbiased human advice',
      text: 'Our goal is to find you the best mortgage we can, whether it’s from our marketplace or not. You can reach your personal mortgage shopping expert via phone, email, or text.'
    },
    Image: {
      svg: Handshake,
      imageName: 'handshake.svg',
      height: 60
    }
  }
];
