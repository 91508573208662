import { colors } from '@rategravity/marketing-components';
import { createOwnUpComponent, fonts } from '@rategravity/own-up-component-library';
import React from 'react';
import StarsSVG from '../../images/reviews/yotpo-4-9stars.svg';
import { LandingPageHeaderText } from '../typography';

const HeadlineTextWithStarsWrapper = createOwnUpComponent('div', {
  flexDirection: 'column',
  alignItems: 'center',
  display: 'flex',
  maxWidth: '580px',
  textAlign: 'center',
  '& > *:not(:last-child)': {
    marginBottom: '20px'
  }
});

const FiveStars = () => (
  <img
    alt="5-star review"
    src={StarsSVG}
    style={{
      width: '250px',
      height: '50px'
    }}
  />
);

const Subtext = createOwnUpComponent('div', {
  ...fonts.GRAPHIK_REGULAR,
  fontSize: '20px',
  lineHeight: '124%',
  textAlign: 'center',
  color: colors.LOGO_SHADOW,
  variants: {
    smallAndDown: {
      fontSize: '15px'
    }
  }
});

interface HeadlineTextWithStarsProps {
  headline: string;
  subtext: string;
}

export const HeadlineTextWithStars = ({ headline, subtext }: HeadlineTextWithStarsProps) => (
  <HeadlineTextWithStarsWrapper>
    <LandingPageHeaderText>{headline}</LandingPageHeaderText>
    <Subtext>{subtext}</Subtext>
    <FiveStars />
  </HeadlineTextWithStarsWrapper>
);
