import { buildMediaQueries, ShopCTAButton } from '@rategravity/marketing-components';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../modules/colors';

const Anchor = styled('a')`
  margin: 0 auto;
  text-decoration: none;
  text-align: center;
  ${buildMediaQueries('mediumAndDown', {
    width: '75%',
    marginBottom: '20px'
  })}
`;

const CTAButton = styled(ShopCTAButton)`
display: inline-block;
  width: 215px;
  background-color: ${colors.LOGO_SHADOW};
  border-color: ${colors.LOGO_SHADOW};
  color: ${colors.WHITE};
  :hover {
    background-color: ${colors.PRIMARY}
    border-color: ${colors.PRIMARY};
    color: ${colors.WHITE};
  }
  ${buildMediaQueries('mediumAndDown', {
    width: '100%'
  })}
`;

export const AnchoredButton = ({ action }: { action: 'Buying' | 'Refinance' }) => (
  <Anchor
    href={
      action === 'Refinance'
        ? `${process.env.GATSBY_ONBOARDING}/${action.toLocaleLowerCase()}`
        : `${process.env.GATSBY_DISCOVER}/${action.toLocaleLowerCase()}`
    }
    aria-label={`${action} with Own Up!`}
  >
    <CTAButton>{action === 'Buying' ? action : 'Refinancing'}</CTAButton>
  </Anchor>
);

const CallToActionWrapper = styled('section')`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  ${buildMediaQueries('mediumAndDown', {
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '60px'
  })}
`;

export const CallToAction = () => (
  <CallToActionWrapper>
    <AnchoredButton action="Buying" />
    <AnchoredButton action="Refinance" />
  </CallToActionWrapper>
);
