import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts
} from '@rategravity/own-up-component-library';
import { Link } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import { useMixpanel } from '../../hooks/use-mixpanel';
import { colors } from '../../modules/colors';

interface MixpanelEvent {
  name: string;
  properties: Record<string, string>;
}

interface OwnUpLinkProps {
  link: string;
  label: string;
  id?: string;
  mixpanelEvent?: MixpanelEvent;
  newWindow?: boolean;
}

export const OwnUpLink = ({
  link,
  label,
  children,
  id,
  mixpanelEvent,
  newWindow = false
}: PropsWithChildren<OwnUpLinkProps>) => {
  const mixpanel = useMixpanel();
  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={link}
      aria-label={label}
      id={id}
      onClick={() => {
        if (mixpanelEvent) {
          mixpanel.track(mixpanelEvent.name, mixpanelEvent.properties);
        }
      }}
      {...(newWindow ? { target: '_none', rel: 'noreferrer' } : {})}
    >
      {children}
    </Link>
  );
};

export const JobsLink = ({ children }: PropsWithChildren<{}>) => (
  <a
    style={{ textDecoration: 'none' }}
    href="https://jobs.lever.co/ownup/"
    aria-label="Own Up Jobs Page on Lever"
  >
    {children}
  </a>
);

export const SignInLink = ({ children }: PropsWithChildren<{}>) => (
  <a
    style={{ textDecoration: 'none' }}
    href={`${process.env.GATSBY_MY}/auth/login`}
    aria-label="Sign In to Account"
  >
    {children}
  </a>
);

const UnderlinedLinkStyle = createOwnUpStyle({
  cursor: 'pointer',
  position: 'relative',
  width: '100%',
  height: 'auto',
  textDecoration: 'none',
  backgroundPosition: '0% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '0% 2px',
  transition: 'background-size 0.3s ease-in-out',
  ':hover, :focus': {
    backgroundSize: '100% 2px'
  }
});

// Classic Blue color (default, but can take any color as prop) + Graphik font
const UnderlinedLinkClassicStyle = ({ color }: { color?: string }) =>
  createOwnUpStyle({
    ...UnderlinedLinkStyle,
    ...fonts.GRAPHIK_MEDIUM,
    fontFamily: "'Graphik-Medium', 'Arial', 'sans-serif'",
    color: color || colors.CLASSIC_BLUE,
    backgroundImage: `linear-gradient(${color || colors.CLASSIC_BLUE}, ${
      color || colors.CLASSIC_BLUE
    })`
  });

// Primary (dark green) color + Tiempos font
const UnderlinedLinkAltStyle = createOwnUpStyle({
  ...UnderlinedLinkStyle,
  color: colors.PRIMARY,
  backgroundImage: `linear-gradient(${colors.PRIMARY}, ${colors.PRIMARY})`,
  variants: {
    xsmall: {
      wordBreak: 'break-all'
    }
  }
});

export const UnderlinedLink = createOwnUpComponent('a', UnderlinedLinkClassicStyle);

export const UnderlinedLinkInternal = createOwnUpComponent(Link, UnderlinedLinkClassicStyle);

export const UnderlinedLinkAlt = createOwnUpComponent('a', UnderlinedLinkAltStyle);

export const UnderlinedButton = createOwnUpComponent('button', {
  ...UnderlinedLinkClassicStyle,
  backgroundColor: 'transparent',
  border: 'none',
  fontSize: 16,
  maxWidth: 106
});

export const LinkWrapper = createOwnUpComponent('div', {
  display: 'flex',
  justifyContent: 'center'
});
