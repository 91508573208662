import { createOwnUpComponent } from '@rategravity/own-up-component-library';
import React from 'react';
import { FindYourBestMortgageReviewProps } from '../../../data/content/find-your-best-mortgage-mktg02/reviews';
import GreenDotsBlobSVG from '../../../images/green-dots-blob-4.svg';
import StarsSVG from '../../../images/reviews/yotpo-5-stars.svg';
import { ImageWithBackground } from '../../image-with-background';
import { BoldTextStyle, QuoteStyle } from '../../typography/icon-landing';

export const FiveStars = () => (
  <img
    alt="5-star review"
    src={StarsSVG}
    style={{
      width: '272px',
      height: '19px'
    }}
  />
);

export const Quote = createOwnUpComponent('div', {
  ...QuoteStyle,
  textAlign: 'center',
  ':before': {
    content: '"“"'
  },
  ':after': {
    content: '"”"'
  },
  variants: {
    xsmall: {
      maxWidth: '90%'
    }
  }
});

export const Attribution = createOwnUpComponent('div', { ...BoldTextStyle });

const ReviewWithImageWrapper = createOwnUpComponent('div', {
  width: '290px',
  flexDirection: 'column',
  alignItems: 'center',
  display: 'flex',
  padding: '0px 30px',
  '& > *:not(:first-child, :last-child)': {
    marginBottom: '20px'
  },
  variants: {
    mediumAndDown: {
      padding: '0px 0px 50px'
    }
  }
});

export interface ReviewWithImageProps extends Omit<FindYourBestMortgageReviewProps, 'image'> {
  Image: JSX.Element;
}

export const ReviewWithImage = ({ Image, quote, attribution }: ReviewWithImageProps) => {
  return (
    <ReviewWithImageWrapper>
      <ImageWithBackground Image={Image} backgroundImagePath={GreenDotsBlobSVG} />
      <FiveStars />
      <Quote>{quote}</Quote>
      <Attribution>{attribution}</Attribution>
    </ReviewWithImageWrapper>
  );
};
