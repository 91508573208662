import { createOwnUpComponent, OwnUpIcon } from '@rategravity/own-up-component-library';
import { Link } from 'gatsby';
import React from 'react';
import { colors } from '../../../modules/colors';

const StyledHr = createOwnUpComponent('hr', {
  border: `.5px solid ${colors.GREY}`,
  backgroundColor: colors.AQUA
});

const IconWrapper = createOwnUpComponent('div', {
  backgroundColor: colors.AQUA,
  padding: '19px 16px 23px',
  display: 'flex',
  justifyContent: 'center'
});

export const Header = () => (
  <header>
    <IconWrapper>
      <Link to="/" aria-label="Own Up home page" style={{ width: '32px', marginRight: '12px' }}>
        <OwnUpIcon icon="OwnUpLogoNoBackdrop" style={{ height: '32px', marginTop: '3px' }} />
      </Link>
      <OwnUpIcon icon="OwnUpWordmark" style={{ height: '37px' }} />
    </IconWrapper>
    <StyledHr aria-hidden={true} />
  </header>
);
