import { ShopCTAButton, ValueProps } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { OwnUpLink } from '../../../components/links';
import { startNowValueProps } from '../../../data/content/start-now/value-props';
import { metadata } from '../../../data/metadata/images';
import { colors } from '../../../modules/colors';

const ValuePropsStyle = createOwnUpStyle({
  gridTemplateColumns: '1fr',
  gridAutoFlow: 'row',
  gridTemplateRows: 'auto auto auto',
  maxWidth: '506px'
});

const ValuePropsWrapperStyle = createOwnUpStyle({
  marginTop: '56px',
  '& .catchphrase > .ou-text': {
    ...fonts.GRAPHIK_MEDIUM,
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '0em'
  },
  '& .primary-text > .ou-text': {
    fontSize: '20px',
    lineHeight: '33px',
    letterSpacing: '0em'
  },
  variants: {
    smallAndDown: {
      margin: '56px 10% 0',
      '& .catchphrase > .ou-text': {
        ...fonts.GRAPHIK_MEDIUM,
        fontSize: '24px',
        lineHeight: '30px'
      },
      '& .primary-text > .ou-text': {
        fontSize: '15px',
        lineHeight: '25px'
      }
    }
  }
});

const ValuePropsWrapper = createOwnUpComponent('div', ValuePropsWrapperStyle);

const CTAButton = styled(ShopCTAButton)`
  display: inline-block;
  background-color: ${colors.CLASSIC_BLUE};
  color: ${colors.WHITE};
`;

const ButtonWrapperStyle = createOwnUpStyle({
  margin: '0 auto',
  textDecoration: 'none',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center'
});

const ButtonWrapper = createOwnUpComponent('div', ButtonWrapperStyle);

export const StartNowValueProps = () => {
  return (
    <>
      <ValuePropsWrapper>
        <ValueProps valueProps={startNowValueProps} metadata={metadata} style={ValuePropsStyle} />
      </ValuePropsWrapper>
      <ButtonWrapper>
        <OwnUpLink
          label="Build your profile in 4 minutes"
          link={`${process.env.GATSBY_DISCOVER}/get-started`}
          newWindow={true}
        >
          <CTAButton>Build your profile in 4 minutes</CTAButton>
        </OwnUpLink>
      </ButtonWrapper>
    </>
  );
};
